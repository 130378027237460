@import "helper";
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";


body,
.dropdown-menu{
  font-family: 'Montserrat', sans-serif;
  background: rgb(225, 225, 225);
  padding: 0!important;
}

.show.collapse{
  border-bottom:2.5px solid #b5b5b5;
  width: 100%;
  
}


.z-index-upper{z-index:99;}

.z-index-up{z-index:9999;}
.fs-7{font-size: 0.7em;}
.nav-link{padding: 0;}

.overflow-x-hidden{overflow-x: hidden};
.overflow-y-hidden{overflow-y: hidden};


mdb-sidenav-content{
  div{
    overflow-x: hidden;
  }
}

mdb-accordion{
  width: 30%;
  border: none;
  margin: 0;
}

.NavVh{
  height: 5.7vh;
}
@media (max-width: 460px) {
  .NavVh{
    height: 6vh;
  }
}

.vh-7{
  height: 7vh;
}

.vh-10{
  height: 10vh;
}

mdb-accordion-item{
  border: none !important;
  margin: 0;
}

.accordion-button{
  padding:0em 0em 0em 0em;
}



.accordion-button::after{
  margin:1em;
}

.accordion-body,
ul,
li{
  padding: 0.1em;
}


.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}


.text-primary{
  color: color('blue')!important;
}


.bg-primary{
  background-color: color('blue') !important;
}


.btn-primary {
  background-color: color('blue');
  border-color: color('blue');
  color: #fff;
}

.btn-outline-primary{
  border-color: color('blue');
  color: color('blue');
  border-width: 1px;
  border-color: rgb(189, 189, 189);
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:disabled {
  border-color: color('blue');
  color: color('blue');
}

.btn:not(.btn-floating){
  border-radius: calc(0.5rem - 1px);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:disabled, .btn-check + .btn:hover {
  background-color: color('blue-hover');
}

.error-message, .success-message {
  top: 35px;
}

.alert-success {
  background-color: #d6f0e0;
  color: #0d6832;
}

.alert-error {
  background-color: #f9e1e5;
  color: #af233a;
}

.alert-warning {
  background-color: #fbf0da;
  color: #73510d;
}

.alert-info {
  background-color: #d9edf7;
  color: #0c5460;
}



mdb-form-control{
  background-color: white;
  border: none;
  
}

.form-control{font-size: 1em !important;}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading{
  border-color: color('blue');
  box-shadow: -1px 0 0 0 color('blue'), 0 1px 0 0 color('blue'), 0 -1px 0 0 color('blue');
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: color('blue');
  box-shadow: 0 1px 0 0 color('blue');
  border-top: 1px solid transparent;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: color('blue');
  box-shadow: 1px 0 0 0 color('blue'), 0 -1px 0 0 color('blue'), 0 1px 0 0 color('blue');
}

.form-outline .form-control:focus ~ .form-label {
  color: color('blue');
}


.datatable.datatable-sm tbody tr:last-child{
  //border-bottom: 1px solid #dee2e6;
  height: auto;
}

.datatable.datatable-sm th, .datatable.datatable-sm td{
  padding: 0.5rem 0.5rem;
}

.loading-modal-fullpage > .modal-content{
  background-color: #00000050;
  color: white;
  justify-content: center;
}

.datatable.datatable-sm th, .datatable.datatable-sm td, .table-sm th, .table-sm td{
  padding: 0.5rem 0.5rem;
}

.datatable.datatable-sm th, .datatable.datatable-sm td, .table-sm th, .table-sm td {
  padding: 0.5rem 1.4rem;
}

.form-check-input:checked:focus:before, .form-check-input:focus:before{
  box-shadow: none!important;
}


.extra-small{
  font-size: 0.8rem!important;
}

.bolderLess{font-weight: 500;}

.btn-group, .btn-group:hover .btn-group-vertical, .btn-group-vertical:hover,
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.show{
  box-shadow: none!important;
}
.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child){
  margin: 0;
}


//.btn-group:last-child .btn:not(:last-child):not(.dropdown-toggle){
//  border-top-right-radius: 0.25rem!important;
//  border-bottom-right-radius: 0.25rem!important;
//}


.colossus-tabs mdb-tabs ul {
  background: white;
  border-radius: 0.5rem;
}

.colossus-tabs mdb-tabs ul li:first-child a{
  border-bottom-left-radius: 0.5rem!important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color: color('blue')!important;
  border-color: color('blue')!important;
}

.sidenav-link.active{
  background-color: rgba(251, 251, 251, 0.05) !important;
  color: #fff!important;
}

.sidenav-link.active>i{
  color: #fff!important;
}



.fw-500{font-weight: 500;}