.word-break-word {
  word-break: break-word;
}


.mouse-pointer{
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input:not(.inputArrow)::-webkit-outer-spin-button,
input:not(.inputArrow)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.45);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.disabled-card{
  opacity: 0.5;
  background: #ccc;
  pointer-events: none;
  cursor: default;
}

td{
  vertical-align: middle;
}
